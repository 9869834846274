@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2-Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2-Book.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2-Medium.ttf");
	font-weight: 400;
}

:root {
	--border-width: 0px;
	--lightpurple: #c6c5ff;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #FFFFFF !important;
	border: var(--border-width) solid white;
	outline: none;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 75px;
}

body {
	padding-top: 93px;
	font-weight: 400;
	position: relative;
	overflow-x: hidden;
	background-color: #121212 !important;
	color: #FFFFFF !important;
	font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	line-height: normal;
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

nav.navbar {
	padding: 18px 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 99;
	transition: 0.32s ease-in-out;
	border-bottom: 1 px solid #FFFFFF !important;
}

nav.navbar.scrolled {
	padding: 0 !important;
	/* top: -100% !important; */
}

.navbar-link {
	position: relative;
	opacity: 0.5;
	margin: 0 10px;
	transition: 0.3s ease-in-out;
}

.navbar-link:hover, .navbar-link.active {
	opacity: 1;
}

.navbar-link::before {
	content: "";
	height: 2px;
	width: 0;
	position: absolute;
	background-color: #FFFFFF;
	bottom: 0;
	left: 50%;
	transition: 0.2s ease-in-out;
}


span.navbar-text {
	display: flex;
	align-items: center;
}

.social-icon {
	display: inline-block;
	margin-left: 14px;
}

.social-icon a {
	width: 42px;
	height: 42px;
	background-color: rgba(217, 217, 217, 0.1);
	display: inline-flex;
	border-radius: 50%;
	margin-right: 6px;
	justify-content: center;
	align-items: center;
	line-height: 1;
	border: 1px solid rgba(255, 255, 255, 0.5)
}

.social-icon a::before {
	content: "";
	z-index: 1;
	width: 42px;
	height: 42px;
	position: absolute;
	background-color: white;
	border-radius: 50%;
	transform: scale(0);
	transition: 0.2s ease-in-out;
}

.social-icon a:hover::before {
	transform: scale(1)
}

.social-icon a img {
	z-index: 1;
	width: 40%;
	transition: 0.2s;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
	font-weight: 700;
	color: #FFFFFF;
	border: 1px solid #FFFFFF;
	padding: 18px 34px;
	font-size: 18px;
	margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar-text button span {
	z-index: 1 !important;
	transition: 0.3s ease-in-out;
}

.navbar-text button::before {
	content: "";
	width: 0%;
	height: 100%;
	position: absolute;
	background-color: #FFFFFF;
	left: 0;
	top: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}

.navbar-text button:hover span {
	color: #121212 !important;
}

.navbar-text button:hover::before {
	width: 100% !important;
	height: 100%;
	content: "";
}

.toggler {
	border: none;
}

.toggler:active, .toggler:focus {
	outline: none !important;
	border: none !important;
}

.toggler:focus {
	box-shadow: none;
}

.toggler span {
	height: 3px;
	width: 35px;
	border-radius: 5px;
	background-color: white;
	margin: 10px 0;
	display: block;
}

.ProjectModal {
	color: black;
	background-color: rgb(33, 37, 41);
	border: 1px solid rgba(255, 255, 255, 0.2); 
	position:relative;
	z-index: 10000
}
/* Mobile - increase fonts */
@media (max-width: 768px) {
	body {
		font-size: 1.25rem;
	}

	.lets-get-in-touch-button {
		font-size: 1.75rem;
	}

	.project-modal-button, .close-project-modal-button {
		font-size: 1.25rem;
		padding: 0.75rem;
		border-radius: 0.7rem;
	}

	.ProjectModal h2 {
		font-size: 2rem;
		margin: 1rem 0
	}

	.button {
		font-size: 1.5rem;
	}

	.react-multiple-carousel__arrow {
		z-index: 0 !important;
	}

	.modal.fade,
	.modal.fade.show,
	.modal-backdrop.fade,
	.modal-backdrop.fade.show {
	  transition: none !important;
	  animation: none !important;
	}

	.modal-dialog-centered {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: calc(100% - (1.75rem * 2));
	}
}

@media (hover: none) and (pointer: coarse) {
	.social-icon a {
		width: 4rem;
		height: 4rem;
		background-color: rgba(217, 217, 217, 0.1);
		display: inline-flex;
		border-radius: 50%;
		margin: 3px;
		justify-content: center;
		align-items: center;
		line-height: 1;
		border: 1px solid rgba(255, 255, 255, 0.5)
	}

	.social-icon a::before {
		content: "";
		display: none;
		z-index: 1;
		width: 0px;
		height: 0px;
		position: absolute;
		background-color: transparent;
		border-radius: 50%;
		transform: scale(0);
	}
	
	.social-icon a:hover::before {
		transform: scale(0);
		display: none;
	}

	.social-icon > a:hover > img {
		filter: none;
	}

	.navbar-link {
		font-size: 1.5rem
	}

	#form input, #form textarea {
		font-size: 1.25rem !important;
	}

	.navbar-brand {
		font-size: 2rem
	}

	.navbar-text button:hover span {
		color: white !important;
	}
	
	.navbar-text button:hover::before {
		display: none;
		width: 0 !important;
		height: 0 !important;
	}

	.navbar-text button::before {
		content: "";
		width: 0;
		height: 0;
		background-color: #FFFFFF;
		left: 0;
		top: 0;
		z-index: -1;
		transition: 0s;
	}

	.project-modal-button:hover {
		opacity: 1 !important;
		background-color: var(--bs-primary);
	}

	.project-modal-button:active {
		opacity: 1 !important;
		background-color: var(--bs-primary);
	}
}

.project-section-heading {
	margin-top: 1rem
}

#contact {
	margin: 5rem 0;
	padding: 5rem 0;
	border: 2px solid white;
	border-radius: 2rem;
	/* background: linear-gradient(to right, rgb(186, 18, 186), rgb(75, 0, 236)) */
	background-color: var(--lightpurple);
}

#contact h2 {
	font-size: 2rem !important;
	margin: 2rem 0;
	color: white !important;
}

#contact form input, #contact form textarea {
	background-color: rgba(255, 255, 255, 0.1);
	border: 2px solid rgba(255, 255, 255, 0.2);
	border-radius: 1rem;
	font-size: 1rem;
	font-weight: 200 !important;
	padding: 1rem 2rem;
	margin: 0.1rem;
	color: white !important;
	width: 100%;
	resize: none;
}

#contact form button, #open-modal {
	color: black !important;
	background-color: white;
	border: 2px solid white;
	padding: 1rem 2rem !important;
	margin: 2rem 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: white;
	opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: white;
  }

.section-heading {
	font-size: 3rem !important;
	font-weight: 700 !important;
}

.footer {
	padding-bottom: 5rem
}

.social-icon svg path {
	fill: white
}

#page-code {
	opacity: 0.5;
}